/* eslint-disable max-lines */
/* prettier-ignore */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Types from '@health/queries/types';

import { gql } from '@apollo/client';
import { HPageInfoFragmentFragmentDoc } from '../../../../../gql/fragments/__generated__/HPageInfo';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GuidedCareProgramListQueryVariables = Types.Exact<{
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  after?: Types.InputMaybe<Types.Scalars['String']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  before?: Types.InputMaybe<Types.Scalars['String']>;
  filter?: Types.InputMaybe<Types.GuidedCareHealthProgramFilter>;
  sortBy?: Types.InputMaybe<Types.GuidedCareHealthProgramSortingInput>;
}>;


export type GuidedCareProgramListQuery = { __typename?: 'Query', guidedCareHealthPrograms?: { __typename?: 'GuidedCareHealthProgramCountableConnection', pageInfo: { __typename?: 'H_PageInfo', endCursor?: string | null, hasNextPage: boolean, hasPreviousPage: boolean, startCursor?: string | null }, edges: Array<{ __typename?: 'GuidedCareHealthProgramCountableEdge', node: { __typename?: 'GuidedCareHealthProgram', id: string, name?: string | null, description?: string | null, price?: number | null, lastModifiedDate?: any | null, isActive?: boolean | null, createdDate?: any | null, createdBy?: string | null, guidedCareHealthProgramTemplate?: { __typename?: 'HealthProgramTemplate', id: string, name?: string | null } | null } }> } | null };


export const GuidedCareProgramListDocument = gql`
    query GuidedCareProgramList($first: Int, $after: String, $last: Int, $before: String, $filter: GuidedCareHealthProgramFilter, $sortBy: GuidedCareHealthProgramSortingInput) {
  guidedCareHealthPrograms(
    first: $first
    after: $after
    last: $last
    before: $before
    filter: $filter
    sortBy: $sortBy
  ) {
    pageInfo {
      ...HPageInfoFragment
    }
    edges {
      node {
        id
        name
        description
        price
        guidedCareHealthProgramTemplate {
          id
          name
        }
        lastModifiedDate
        isActive
        createdDate
        createdBy
      }
    }
  }
}
    ${HPageInfoFragmentFragmentDoc}`;

/**
 * __useGuidedCareProgramListQuery__
 *
 * To run a query within a React component, call `useGuidedCareProgramListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGuidedCareProgramListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGuidedCareProgramListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      after: // value for 'after'
 *      last: // value for 'last'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useGuidedCareProgramListQuery(baseOptions?: Apollo.QueryHookOptions<GuidedCareProgramListQuery, GuidedCareProgramListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GuidedCareProgramListQuery, GuidedCareProgramListQueryVariables>(GuidedCareProgramListDocument, options);
      }
export function useGuidedCareProgramListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GuidedCareProgramListQuery, GuidedCareProgramListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GuidedCareProgramListQuery, GuidedCareProgramListQueryVariables>(GuidedCareProgramListDocument, options);
        }
export type GuidedCareProgramListQueryHookResult = ReturnType<typeof useGuidedCareProgramListQuery>;
export type GuidedCareProgramListLazyQueryHookResult = ReturnType<typeof useGuidedCareProgramListLazyQuery>;
export type GuidedCareProgramListQueryResult = Apollo.QueryResult<GuidedCareProgramListQuery, GuidedCareProgramListQueryVariables>;