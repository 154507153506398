import { getAutocompleteFilter, createAutocompleteOption } from "@toolkit/ui";
import { useUsersEmailsAutocompleteQuery } from "./gql";
export const createUserEmailAutocompleteOption = (user) => {
    return createAutocompleteOption({ id: user === null || user === void 0 ? void 0 : user.id, email: user === null || user === void 0 ? void 0 : user.email }, "id", "email");
};
export const getUsersEmailsAutocompleteFilter = ({ name, multiple, filter }) => {
    return getAutocompleteFilter({
        name,
        multiple,
        accessor: "users",
        query: useUsersEmailsAutocompleteQuery,
        labelBy: "email",
        backendAccessor: "email",
        filtersForQuery: filter,
    });
};
