import { FormAutocomplete, Grid } from "@toolkit/ui";
import { useCustomForm } from "@toolkit/core";
import { FormProvider, UseFormReturn } from "react-hook-form";
import {
  guidedCareTemplateActivitiesItemsFilterFormDefaultValues,
  guidedCareTemplateActivitiesItemsFilterFormSchema,
  IGuidedCareTemplateActivitiesItemsFilterFormValues,
} from "./GuidedCareTemplateActivitiesItemsFilterFormSchema";
import { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle } from "react";
import { guidedCareActivityTypeOptions } from "@health/enum-options";
import { useTranslation } from "@toolkit/i18n";
import { MedicalFormsAutocomplete, MedicalMessagesAutocomplete } from "@health/autocompletes";

type GuidedCareTemplateActivitiesItemsFormProps = {
  values?: IGuidedCareTemplateActivitiesItemsFilterFormValues;
  onChange: (values: IGuidedCareTemplateActivitiesItemsFilterFormValues) => void;
};

export type GuidedCareTemplateActivitiesItemsFormRef = {
  getForm: () => UseFormReturn<IGuidedCareTemplateActivitiesItemsFilterFormValues>;
  submit: () => void;
};

const GuidedCareTemplateActivitiesItemsFilterFormForwardRef: ForwardRefRenderFunction<
  GuidedCareTemplateActivitiesItemsFormRef,
  GuidedCareTemplateActivitiesItemsFormProps
> = (props, ref) => {
  const { values, onChange } = props;

  const { t } = useTranslation("gcadmin");

  const form = useCustomForm<IGuidedCareTemplateActivitiesItemsFilterFormValues>({
    defaultValues: guidedCareTemplateActivitiesItemsFilterFormDefaultValues,
    schema: guidedCareTemplateActivitiesItemsFilterFormSchema,
  });

  const { setValues, handleSubmit } = form;

  const onSubmit = (formValues: IGuidedCareTemplateActivitiesItemsFilterFormValues) => {
    onChange(formValues);
  };

  useImperativeHandle(ref, () => ({
    getForm: () => form,
    submit: handleSubmit(onSubmit),
  }));

  useEffect(() => {
    if (values) {
      setValues(values);
    }
  }, [values]);

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <FormAutocomplete name={"activityType"} label={t("Activity Type")} options={guidedCareActivityTypeOptions} />
          </Grid>

          <Grid item xs={6}>
            <MedicalMessagesAutocomplete name={"medicalMessages"} multiple />
          </Grid>

          <Grid item xs={6}>
            <MedicalFormsAutocomplete name={"medicalForms"} multiple />
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  );
};

export const GuidedCareTemplateActivitiesItemsFilterForm = forwardRef(GuidedCareTemplateActivitiesItemsFilterFormForwardRef);
