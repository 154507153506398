import { createAutocompleteOption } from "@toolkit/ui";
import { pickLocalizedValue } from "@toolkit/i18n";
export const createMedicalFormClassificationAutocompleteOption = (medicalForm) => {
    var _a;
    return createAutocompleteOption({
        id: medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.id,
        code: medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.code,
        name: medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.name,
        nameAr: medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.nameAr,
        classifications: ((_a = medicalForm === null || medicalForm === void 0 ? void 0 : medicalForm.medicalFormClassifications) === null || _a === void 0 ? void 0 : _a.map(classification => ({
            id: classification === null || classification === void 0 ? void 0 : classification.id,
            name: classification === null || classification === void 0 ? void 0 : classification.name,
            rangeFrom: classification === null || classification === void 0 ? void 0 : classification.rangeFrom,
            rangeTo: classification === null || classification === void 0 ? void 0 : classification.rangeTo,
        }))) || [],
    }, "id", item => `${item === null || item === void 0 ? void 0 : item.code} - ${pickLocalizedValue(item === null || item === void 0 ? void 0 : item.name, item === null || item === void 0 ? void 0 : item.nameAr)}`);
};
