import { z } from "zod";
import { zodEnumSchema } from "@health/enum-options";
import { zodSchema } from "@health/autocompletes";

export const guidedCareTemplateActivitiesItemsFilterFormSchema = z.object({
  activityType: zodEnumSchema.guidedCareActivityType.nullish(),
  medicalMessages: z.array(zodSchema.medicalMessage),
  medicalForms: z.array(zodSchema.medicalForm),
});

export type IGuidedCareTemplateActivitiesItemsFilterFormValues = z.infer<typeof guidedCareTemplateActivitiesItemsFilterFormSchema>;

export const guidedCareTemplateActivitiesItemsFilterFormDefaultValues: Partial<IGuidedCareTemplateActivitiesItemsFilterFormValues> = {
  activityType: undefined,
  medicalMessages: [],
  medicalForms: [],
};
